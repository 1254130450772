import * as React from "react";
import Layout from "../components/layout";
import { GatsbySeo } from "gatsby-plugin-next-seo";
import { graphql } from "gatsby";
import { PortableText } from "@portabletext/react";
import styled from "styled-components";

const LegalWrapper = styled.div`
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
  margin-top: 150px;

  @media (min-width: 1200px) {
    margin-top: 250px;
  }
`;

const LegalInner = styled.div`
  padding: 25px;
`;

const DatenschutzTemplatePage = ({ location, data }) => {
    const ptComponents = {
        marks: {
          link: ({ children, value }) => {
            const rel = !value.href.startsWith("/")
              ? "noreferrer noopener"
              : undefined;
            return (
              <a href={value.href} rel={rel} target="_blank">
                {children}
              </a>
            );
          },
        },
      };
  return (
    <Layout path={location.pathname}>
      <GatsbySeo
        title={data.sanityDatenschutz.seo.title}
        description={data.sanityDatenschutz.seo.description}
      />
      <LegalWrapper>
        <LegalInner>
      <h1>{data.sanityDatenschutz.title}</h1>
      <PortableText value={data.sanityDatenschutz.content} components={ptComponents} />
      </LegalInner>
      </LegalWrapper>
    </Layout>
  );
};

export const query = graphql`
  query DatenschutzTemplateQuery {
    sanityDatenschutz {
      title
      content: _rawContent
      seo {
        title
        description
      }
    }
  }
`;

export default DatenschutzTemplatePage;
